import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

const Terminologies = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="col-md-12">
      <div className="terminology_view_box teamperfomance">
        <div className="joinings_viewBox_head">
          <h5>{t("terminologies")}</h5>
        </div>

        <div className="tab-content" id="ex2-content">
          <div className="terminology_tile">
            {!data ? (
              <div className="teammbrs_cnt_name_dtl">
                <div className="teammbrs_cnt_name">
                  <Skeleton count={9} />
                </div>
              </div>
            ) : data?.length === 0 ? (
              <div className="no-data-div">
                <div className="no-data-div-image">
                  <img src="/images/nodata-image.png" alt="" />
                </div>
                <p>{t("noDataFound")}</p>
              </div>
            ) : (
              data?.map((row) => (
                <>
                  <ul>
                    <li>
                      <div className="terminology_title">{t(row.title)}</div>
                      <div className="terminology_desc">{row.description}</div>
                    </li>
                  </ul>
                </>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terminologies;
