
const LabelledButton = ({ text, className }) => {
    return (
        <>
            <button type="button" className="btn btn-labeled btn-primary">
                <span className="btn-label"><i className={className}></i></span>{text}</button>
        </>
    )
};

export default LabelledButton;