import { React } from "react";
import ProductDetails from "../../components/shopping/ProductDetails";

const ProductDetailsLayout = () => {
    return (
        <>
            <div className="page_head_top">Product Details</div>
            <ProductDetails />
        </>
    )
}

export default ProductDetailsLayout