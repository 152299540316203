import TableContent from "../Common/table/TableContent";
import TablePagination from "../Common/table/TablePagination";
import { ApiHook } from "../../hooks/apiHook";
import { useState } from "react";
import SubmitButton from "../Common/buttons/SubmitButton";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SalesTable = (props) => {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const headers = [
    t("invoice_no"),
    t("username"),
    t("type"),
    t("status"),
    t("amount"),
    t("date"),
  ];
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const [selectedStartDate, setSelectedStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const OrderDetails = ApiHook.CallOrders(
    selectedStartDate,
    selectedEndDate,
    props.currentPage,
    itemsPerPage
  );
//   const orderHeaders = ApiHook.CallOrderHead();
  const dateHandler = (e) => {
    const { value, name } = e.target;
    if (name == "start_date") {
      setStartDate(value);
    }
    if (name == "end_date") {
      setEndDate(value);
    }
  };

  const SearchHandler = () => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
    props.setCurrentPage(1);
  };

  const resetHandler = () => {
    setSelectedStartDate(moment().startOf("month").format("YYYY-MM-DD"));
    setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
    setSelectedEndDate(moment().endOf("month").format("YYYY-MM-DD"));
    setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
  };
  let startPage = 1;
  const toNextPage = () => {
    if (
      OrderDetails.data.data.currentPage < OrderDetails.data.data.totalPages
    ) {
      props.setCurrentPage(OrderDetails.data.data.currentPage + 1);
    }
  };

  const toLastPage = () => {
    props.setCurrentPage(OrderDetails.data.data.totalPages);
  };
  const toPreviousPage = () => {
    if (OrderDetails.data.data.currentPage > startPage) {
      props.setCurrentPage(OrderDetails.data.data.currentPage - 1);
    }
  };

  const toFirstPage = () => {
    props.setCurrentPage(startPage);
  };
  const handleItemsPerPageChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    setItemsPerPage(selectedValue);
    props.setCurrentPage(1);
  };

  return (
    <>
      <div className="ewallet_table_section">
        <div className="ewallet_table_section_cnt">
          <div className="filter_Section">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-4">
                <div className="right_search_div d-flex gap-1">
                  {/* <div className="downMembBg">
                    <h5>{t("total_bv")}</h5>
                    <h4>{orderHeaders?.data?.data?.bv}</h4>
                  </div> */}
                  {/* <div className="downMembBg">
                    <h5>{t("total_pv")}</h5>
                    <h4>{orderHeaders?.data?.data?.pv}</h4>
                  </div> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="right_search_div d-flex gap-1">
                  <span>start date:</span>
                  <input
                    name="start_date"
                    type="date"
                    class="form-control"
                    onChange={dateHandler}
                    value={startDate}
                  ></input>
                  <span>end date:</span>
                  <input
                    name="end_date"
                    type="date"
                    class="form-control"
                    onChange={dateHandler}
                    value={endDate}
                  ></input>
                  <SubmitButton
                    isSubmitting=""
                    text="Search"
                    className="btn btn-primary"
                    click={SearchHandler}
                    id="searchButton"
                  />
                  <SubmitButton
                    isSubmitting=""
                    text="Reset"
                    className="btn btn-secondary"
                    click={resetHandler}
                    id="resetButton"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive min-hieght-table">
            {OrderDetails.isLoading ? (
              <div>
                <SkeletonTheme>
                  <div style={{ width: "100%" }}>
                    <Skeleton
                      height={"100%"}
                      width={"100%"}
                      borderRadius={"20px"}
                      inline={true}
                      duration={2.5}
                    />
                  </div>
                </SkeletonTheme>
              </div>
            ) : (
              <TableContent
                headers={headers}
                data={OrderDetails?.data?.data?.data}
                type="sales"
              />
            )}
            {OrderDetails?.data?.data?.data?.length !== 0 && (
              <TablePagination
                startPage={startPage}
                currentPage={OrderDetails?.data?.data?.currentPage}
                totalPages={OrderDetails?.data?.data?.totalPages}
                setCurrentPage={props.setCurrentPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                toNextPage={toNextPage}
                toLastPage={toLastPage}
                toPreviousPage={toPreviousPage}
                toFirstPage={toFirstPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesTable;
