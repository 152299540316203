import React from "react";
import { useState } from "react";
import SalesTable from "../../components/Sales/SalesTable";
const Sales = () => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <>
      <SalesTable currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </>
  );
};

export default Sales;
