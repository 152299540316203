import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const ProductForm = ({product}) => {
  const { t } = useTranslation();
  const navigate = useNavigate()

  const handleBack = () => {
    navigate('/shopping')
  };

  return (
    <div className="productFormSec">
      <div className="mb-3">
        <label htmlFor="quantity" className="form-label">
          {t("quantity")}
        </label>
        <input
          type="text"
          className="form-control"
          id="quantity"
          placeholder="1"
          disabled
        />
      </div>
      <div className="mb-3">
        <label htmlFor="total" className="form-label">
          {t("total")}
        </label>
        <input
          type="text"
          className="form-control"
          id="total"
          placeholder={product?.price}
          disabled
        />
      </div>
      <div className="mb-3">
        <label htmlFor="totalPV" className="form-label">
          {t("total_pv")}
        </label>
        <input
          type="text"
          className="form-control"
          id="totalPV"
          placeholder="300"
          disabled
        />
      </div>
      <div className="mb-3">
        <label htmlFor="description" className="form-label">
          {t("description")}
        </label>
        <input
          type="text"
          className="form-control"
          id="description"
          placeholder="Description"
          disabled
        />
      </div>
      <button
        type="button"
        className="btn btn-dark text-white float-start back mt-4 rounded-3"
        onClick={handleBack}
      >
        {t("back")}
      </button>
    </div>
  );
};

export default ProductForm;
