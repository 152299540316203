import { React, useState } from "react";
import { ApiHook } from "../../hooks/apiHook";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "../Common/buttons/SubmitButton";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const ContactDetailsTab = ({ contact, countries }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    register,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      address: contact?.address ?? "",
      address2: contact?.address2 ?? "",
      country: contact?.country?.id ?? "",
      state: contact?.state?.id ?? "",
      city: contact?.city ?? "",
      zipCode: contact?.zipCode ?? "",
      mobile: contact?.mobile ?? "",
    },
  });
  const getRawPhone = (phone, data, event, formattedValue) => {
    setValue(`dial_code`, phone.substring(0, data.dialCode.length));
  };
  const checkMobile = ApiHook.CallRegisterMobileCheck();

  const handleMobileCheck = async (item) => {
    checkMobile.mutateAsync(item);
  };

  const formValues = watch();
  const [phone, setPhone] = useState(contact?.mobile);
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
  const updateContactMutation = ApiHook.CallUpdateContactDetails(formValues);
  const toggleEditMode = () => {
    setIsEditModeEnabled(!isEditModeEnabled);
  };

  const onSubmit = async () => {
    const isValid = await trigger();
    if (isValid && checkMobile?.data?.status) {
      updateContactMutation.mutate(formValues, {
        onSuccess: (res) => {
          if (res.status) {
            queryClient.invalidateQueries({ queryKey: ["profile"] });
            toast.success(res?.data);
            setIsEditModeEnabled(false);
          }
        },
      });
    }
  };

  return (
    <div id="secondTab" className="tabcontent">
      <div className={`editSec ${isEditModeEnabled ? "disabled" : ""}`}>
        <div className="editBg">
          <span
            style={{ textDecoration: "none", cursor: "pointer" }}
            onClick={toggleEditMode}
          >
            <i
              className="fa-solid fa-pen-to-square"
              style={{ color: "#32009c" }}
            ></i>
          </span>
        </div>
      </div>
      <h3>{t("contactDetails")}</h3>
      <div className="tabcontent_form_section">
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3 row tabBlockClass">
              <label
                htmlFor="1"
                className="col-sm-3 col-form-label labelWidthClass"
              >
                {t("addressLine1")}
              </label>
              <div className="col-md-9 col-sm-12 col-12">
                <input
                  {...register("address", {
                    required: t("this_field_is_required"),
                  })}
                  defaultValue={formValues?.address}
                  type="text"
                  id="1"
                  className="form-control"
                  disabled={!isEditModeEnabled}
                />
                {errors.address && (
                  <span className="validation-error-message">
                    {errors.address.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3 row tabBlockClass">
              <label
                htmlFor="2"
                className="col-sm-3 col-form-label labelWidthClass"
              >
                {t("addressLine2")}
              </label>
              <div className="col-md-9 col-sm-12 col-12">
                <input
                  {...register("address2", {
                    required: t("this_field_is_required"),
                  })}
                  defaultValue={formValues?.address2}
                  type="text"
                  className="form-control"
                  id="2"
                  disabled={!isEditModeEnabled}
                />
                {errors.address2 && errors.address2.type === "required" && (
                  <span className="validation-error-message">
                    {errors.address2.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3 row tabBlockClass">
              <label
                htmlFor="3"
                className="col-sm-3 col-form-label labelWidthClass"
              >
                {t("country")}
              </label>
              <div className="col-md-9 col-sm-12 col-12">
                <select
                  {...register("country", {
                    required: t("this_field_is_required"),
                  })}
                  className="form-select"
                  id="3"
                  defaultValue={formValues?.country ?? ""}
                  disabled={!isEditModeEnabled ?? true}
                >
                  {Object.entries(countries)?.map(([key, value]) => (
                    <option key={key} value={value?.id}>
                      {value?.name}
                    </option>
                  ))}
                </select>
                {errors.country && errors.country.type === "required" && (
                  <span className="validation-error-message">
                    {errors.country.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3 row tabBlockClass">
              <label
                htmlFor="4"
                className="col-sm-3 col-form-label labelWidthClass"
              >
                {t("state")}
              </label>
              <div className="col-md-9 col-sm-12 col-12">
                <select
                  {...register("state", {
                    required: t("this_field_is_required"),
                  })}
                  defaultValue={formValues?.state}
                  className="form-select"
                  id="4"
                  disabled={!isEditModeEnabled}
                >
                  <option key="0" value="">
                    {t("select_a_state")}
                  </option>
                  {countries?.map((country) => {
                    if (country.id === Number(formValues.country)) {
                      return country.States.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ));
                    }
                  })}
                </select>
                {errors.state && (
                  <span className="validation-error-message">
                    {errors.state.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3 row tabBlockClass">
              <label
                htmlFor="5"
                className="col-sm-3 col-form-label labelWidthClass"
              >
                {t("city")}
              </label>
              <div className="col-md-9 col-sm-12 col-12">
                <input
                  {...register("city", {
                    required: t("this_field_is_required"),
                  })}
                  type="text"
                  className="form-control"
                  id="5"
                  defaultValue={formValues?.city}
                  disabled={!isEditModeEnabled}
                />
                {errors.city && (
                  <span className="validation-error-message">
                    {errors.city.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3 row tabBlockClass">
              <label
                htmlFor="6"
                className="col-sm-3 col-form-label labelWidthClass"
              >
                {t("zipCode")}
              </label>
              <div className="col-md-9 col-sm-12 col-12">
                <input
                  {...register("zipCode", {
                    required: t("this_field_is_required"),
                    pattern: {
                      value: /^[0-9]+$/,
                      message: t("only_number"),
                    },
                  })}
                  type="text"
                  className="form-control"
                  id="6"
                  defaultValue={formValues?.zipCode}
                  disabled={!isEditModeEnabled}
                />
                {errors.zipCode && (
                  <span className="validation-error-message">
                    {errors.zipCode.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3 row tabBlockClass">
              <label
                htmlFor="7"
                className="col-sm-3 col-form-label labelWidthClass"
              >
                {t("mobileNumber")}
              </label>
              <div className="col-md-9 col-sm-12 col-12">
                {/* <input
                  {...register("mobile", {
                    required: t("this_field_is_required"),
                    minLength:{
                      value:5,
                      message:t('min_length_of_5')
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: t("only_number"),
                    }
                  })}
                  type="text"
                  className="form-control"
                  id="7"
                  defaultValue={formValues?.mobile}
                  disabled={!isEditModeEnabled}
                />
                {errors.mobile && (
                  <span className="validation-error-message">
                    {errors.mobile.message}
                  </span>
                )} */}
                <div>
                  <PhoneInput
                    country={"in"} //initial value
                    inputStyle={{
                      width: "-webkit-fill-available",
                      height: "25px",
                      // borderColor:
                      //   !checkMobile?.data?.status &&
                      //   checkMobile?.data?.data?.code === 1117
                      //     ? "#dc3545"
                      //     : "#dee2e6",
                    }}
                    disabled={!isEditModeEnabled}
                    buttonStyle={{}}
                    inputProps={{ name: "mobile" }}
                    enableSearch={true}
                    value={formValues?.mobile}
                    {...register("mobile", {
                      required: {
                        value: true,
                        message: t("this_field_is_required"),
                      },
                    })}
                    onChange={(phone, data, event, formattedValue) => {
                      setPhone(phone);
                      setValue("mobile", phone, {
                        shouldValidate: true,
                      });
                      handleMobileCheck(phone);
                      getRawPhone(phone, data, event, formattedValue);
                    }}
                    onBlur={async () => await trigger("mobile")}
                  />
                  {!checkMobile?.data?.status &&
                    checkMobile?.data?.data?.code === 1117 && (
                      <span className="error-message-validator">
                        {t("mobile_exists")}
                      </span>
                    )}
                  {errors.mobile && (
                    <span className="validation-error-message">
                      {errors.mobile.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`paymenytLinkBtn ${isEditModeEnabled ? "disabled" : ""}`}
        >
          <SubmitButton
            id={"1"}
            isSubmitting={!isEditModeEnabled}
            click={onSubmit}
            text="update"
            className="btn"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactDetailsTab;
