import { Card } from "react-bootstrap";

import React from "react";

export default function CustomerDashboard() {
  return (
    <>
      <div>
        <div style={{ paddingBottom: "100px" }}> </div>
        <Card style={{ alignItems: "center", borderRadius: "50px" }}>
          <Card.Body style={{ padding: "100px" }}>
            <Card.Title>WELCOME TO COMPANY NAME</Card.Title>
            <Card.Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
