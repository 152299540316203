import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { Offcanvas, Form } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { ApiHook } from "../../hooks/apiHook";
import SubmitButton from "../Common/buttons/SubmitButton";
import { epinCurrencyConverter } from "../../utils/epinCurrencyConversion";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';

const EpinRequest = ({ show, handleClose, amounts, conversionFactor }) => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState({
    amountCount: [],
    epinCount: "",
    expiryDate: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    amountCount: null,
    epinCount: null,
    expiryDate: null,
  });
  const queryClient = useQueryClient();
  const requestMutation = ApiHook.CallEpinRequest();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrorMessage((prevData) => ({
      ...prevData,
      [id]: null,
    }));
    if (value === null || value === "") {
      setErrorMessage((prev) => ({
        ...prev,
        [id]: "*Required",
      }));
    }
  };
  const handleAmountChange = (amountCount) => {
    if (amountCount?.length === 0) {
      setErrorMessage((prev) => ({
        ...prev,
        amountCount: "*Required",
      }));
    } else {
      setErrorMessage((prevData) => ({
        ...prevData,
        amountCount: null,
      }));
    }

    setFormState((prevData) => ({
      ...prevData,
      amountCount,
    }));
  };
  const isFormValid = () => {
    return (
      formState?.amountCount?.length > 0 &&
      formState?.epinCount > 0 &&
      formState?.expiryDate.trim() !== ""
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isFormValid()) {
      return;
    }
    const amountValues = formState.amountCount.map((option) => option.value);
    const data = {
      amountCode: amountValues,
      epinCount: formState.epinCount,
      expiryDate: formState.expiryDate,
    };
    requestMutation.mutateAsync(data, {
      onSuccess: (res) => {
        if (res.status === 200) {
          setErrorMessage({
            amountCount: null,
            transactionPassword: null,
          });
          queryClient.invalidateQueries({ queryKey: ["epin-tiles"] });
          queryClient.invalidateQueries({ queryKey: ["epin-lists"] });
          handleClose(false);
        } else {
          if (res?.data?.code === 1014) {
            setErrorMessage((prevErrors) => ({
              ...prevErrors,
              amountCount: t(res?.data?.description),
            }));
          } else if (res?.data?.code === 1015) {
            setErrorMessage((prevErrors) => ({
              ...prevErrors,
              transactionPassword: t(res?.data?.description),
            }));
          } else if (res?.data?.code === 1045) {
            toast.error(t(res?.data?.description));
          } else if (res?.data?.code === 429) {
            setErrorMessage((prevErrors) => ({
              ...prevErrors,
              amountCount: t(res?.data?.description),
            }));
          } else if (res?.data?.code === 1103) {
            setErrorMessage((prevErrors) => ({
              ...prevErrors,
              expiryDate: t(res?.data?.description),
            }));
          } else if (res?.data?.code) {
            toast.error(res?.data?.description);
          } else {
            toast.error(res?.data?.message);
          }
        }
      },
    });
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{t('epinRequest')}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>{t('amount')}</Form.Label>
            <MultiSelect
              id="amountCount"
              options={epinCurrencyConverter(amounts, conversionFactor)}
              value={formState.amountCount}
              onChange={handleAmountChange}
              labelledBy="Select"
            />
            <div className="required_dropDown">
              {errorMessage?.amountCount ? errorMessage?.amountCount : ""}
            </div>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t('epinCount')}</Form.Label>
            <Form.Control
              id="epinCount"
              value={formState.epinCount}
              placeholder="E-Pin Count"
              required
              onChange={(e) => handleChange(e)}
              isInvalid={errorMessage?.epinCount !== null}
            />
            <Form.Control.Feedback type="invalid">
              {errorMessage?.epinCount}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t('expiryDate')}</Form.Label>
            <Form.Control
              id="expiryDate"
              type="date"
              value={formState.expiryDate}
              placeholder="Expiry Date"
              onChange={(e) => handleChange(e)}
              isInvalid={errorMessage?.expiryDate !== null}
            />
            <Form.Control.Feedback type="invalid">
              {errorMessage?.expiryDate}
            </Form.Control.Feedback>
          </Form.Group>
          <SubmitButton
            isSubmitting={!isFormValid() || requestMutation.isLoading}
            click={handleSubmit}
            text={requestMutation.isLoading ? "Requesting..." : "Request Epin"}
            className={"btn btn-primary submit_btn"}
          />
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default EpinRequest;
